import { Component, Renderer2 } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { NetworkConnectivityComponent } from './components/network-connectivity/network-connectivity.component';
import { OrientationModalComponent } from './components/orientation-modal/orientation-modal.component';
import { EventService } from './services/Events/events.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './services/API/api.service';
import { environment } from 'src/environments/environment';
import { CachingService } from 'src/app/services/cache/caching.service';
import { GlobalService } from 'src/app/services/global/global.service';

declare global {
  interface Window {
    visualizer: boolean;
  }
}
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  orientationError = false;
  browserNotSupported = false;
  orientationErrorType: any;
  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private events: EventService,
    private translate: TranslateService,
    private apiService: ApiService,
    private cachingService: CachingService,
    private globalService: GlobalService,
    private renderer: Renderer2
  ) {
    this.initializeLangApp();
    // to add class to the body for default idea
    this.renderer.addClass(document.body, `idea-${this.globalService.idea}`);
    if (this.platform.is('cordova')) {
      alert('CORDOVA');
    }
    this.orientationCheck();
    const browser = this.getBrowserInfo();
    this.browserControl(browser);
    window.addEventListener(
      'load',
      () => {
        if (navigator.onLine) {
          this.updateConnectionStatus('Online Load', true);
        } else {
          this.updateConnectionStatus('Offline', false);
        }
      },
      false
    );
    window.addEventListener(
      'online',
      () => {
        this.updateConnectionStatus('Online', true);
      },
      false
    );

    window.addEventListener(
      'offline',
      () => {
        this.updateConnectionStatus('Offline', false);
      },
      false
    );
  }

  initializeLangApp() {
    this.translate.setDefaultLang('en');
  }

  async updateConnectionStatus(msg: string, connected: boolean) {
    if (msg === 'Offline' && !connected) {
      const modal = await this.modalController.create({
        component: NetworkConnectivityComponent,
        cssClass: 'one-3d-full-page-modal',
        backdropDismiss: false,
        animated: false,
      });
      return await modal.present();
    } else if (msg === 'Online Load') {
      return;
    } else {
      this.modalController.dismiss();
    }
  }
  async orientationCheck() {
    const device = this.platform.platforms();
    if (window.innerHeight > window.innerWidth && device[0] === 'ipad') {
      // if (this.platform.is('cordova')) {
      this.openModalForOrientation('ipad');
      this.orientationErrorType = 'ipad';
      // }
    }
    window.addEventListener(
      'orientationchange',
      () => {
        // if (this.platform.is('cordova')) {
        if (window.orientation === 90) {
          if (device.includes('ipad')) {
            this.closeOrientationModal();
          } else if (device.includes('iphone') || device.includes('android')) {
            this.openModalForOrientation('mobile');
            this.orientationErrorType = 'mobile';
          }
        } else {
          if (device.includes('ipad')) {
            this.openModalForOrientation('ipad');
            this.orientationErrorType = 'ipad';
          } else if (device.includes('iphone') || device.includes('android')) {
            this.closeOrientationModal();
          }
        }
        // }
      },
      false
    );
  }
  async openModalForOrientation(orientation) {
    this.orientationError = true;
  }
  closeOrientationModal() {
    this.events.publish('events', {
      resize: null,
    });
    this.orientationError = false;
  }
  checkBrowser() {
    const ua = navigator.userAgent;
    let tem;
    let M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return { name: 'Opera', version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1],
    };
  }
  getBrowserInfo() {
    const userAgent = navigator.userAgent;
    let browserName;
    let browserVersion;

    // Detect Chrome
    if (userAgent.match(/Chrome\/(\S+)/)) {
      browserName = 'Chrome';
      browserVersion = userAgent.match(/Chrome\/(\S+)/)[1];
    }
    // Detect Firefox
    else if (userAgent.match(/Firefox\/(\S+)/)) {
      browserName = 'Firefox';
      browserVersion = userAgent.match(/Firefox\/(\S+)/)[1];
    }
    // Detect Safari
    else if (userAgent.match(/Version\/(\S+).*Safari/)) {
      browserName = 'Safari';
      browserVersion = userAgent.match(/Version\/(\S+).*Safari/)[1];
    }
    // Detect Edge
    else if (userAgent.match(/Edg\/(\S+)/)) {
      browserName = 'Edge';
      browserVersion = userAgent.match(/Edg\/(\S+)/)[1];
    }
    // Detect Internet Explorer
    else if (userAgent.match(/MSIE (\S+);/)) {
      browserName = 'Internet Explorer';
      browserVersion = userAgent.match(/MSIE (\S+);/)[1];
    }
    // Detect Opera
    else if (
      userAgent.match(/Opera\/.*?Version\/(\S+)/) ||
      userAgent.match(/Opera\/(\S+)/)
    ) {
      browserName = 'Opera';
      browserVersion = userAgent.match(
        /(?:Opera\/.*?Version\/(\S+))|(?:Opera\/(\S+))/
      )[1];
    } else {
      // For other browsers, you might need to handle them differently
      browserName = 'Other';
      browserVersion = 'N/A';
    }
    if (browserVersion != 'N/A') {
      let versionArray = browserVersion.split('.');
      browserVersion = Number(versionArray[0]);
    }
    return { name: browserName, version: browserVersion };
  }

  browserControl(browser) {
    if (
      browser.name === 'Internet Explorer' ||
      (browser.name === 'Safari' && browser.version < 14) ||
      (browser.name === 'Chrome' && browser.version < 86) ||
      (browser.name === 'Firefox' && browser.version < 90) ||
      (browser.name === 'Edge' && browser.version < 79) ||
      browser.name === 'Opera'
      //edge version and opera version need to be decided from 3d team
    ) {
      this.browserNotSupported = true;
      this.apiService.logGAEvent(
        'uaevent',
        'Content',
        'NotSupportedBrowser::Detail',
        navigator.userAgent
      );
      this.apiService.logGAEvent(
        'uaevent',
        'Content',
        'NotSupportedBrowser::Object',
        JSON.stringify(browser)
      );
    }
  }
}
